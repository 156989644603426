<template>
  <div class="main-container">
    <h1 >Liquor / Club Liquor Licence</h1>
    <BoldLine id="h1-boldline" class="hidden-md-and-down" :options="{color:this.$vuetify.theme.themes.light.secondary,height:'6px',width:'45px'}"></BoldLine>
    <BoldLine id="h1-boldline" class="hidden-lg-and-up" :options="{color:this.$vuetify.theme.themes.light.secondary,height:'4px',width:'30px'}"></BoldLine>
    <div class="part-1">
      <div><h2>1. Please select service</h2></div>
      <div>
        <v-btn-toggle v-model="partOne.selected" tile group>
          <v-btn>
            <div class="btn-slot">
              <div class="tick-cir-div d-flex flex-row-reverse">
                <img v-if="partOne.selected == 0" src="../assets/cir_tick.svg" />
              </div>
              <img class="mb-4 mt-2" src="../assets/landing_application.svg" />
              <h3>Applications</h3>
            </div>
          </v-btn>
          <v-btn>
            <div class="btn-slot">
              <div class="tick-cir-div d-flex flex-row-reverse">
                <img v-if="partOne.selected == 1" src="../assets/cir_tick.svg" />
              </div>
              <img class="mb-4 mt-2" src="../assets/landing_renewal.svg" />
              <h3>Renewal</h3>
            </div>
          </v-btn>
          <v-btn>
            <div class="btn-slot">
              <div class="tick-cir-div d-flex flex-row-reverse">
                <img v-if="partOne.selected == 2" src="../assets/cir_tick.svg" />
              </div>
              <img class="mb-4 mt-2" src="../assets/landing_followup.svg" />
              <h3>Follow Up</h3>
            </div>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <!-- Application -->
    <div class="part-2" v-if="partOne.selected==0"> 
      <div><h2>2. Application for</h2></div>
      <div>
        <v-btn-toggle v-model="partTwo[partOne.selected].selected" tile group>
          <v-btn v-for="(item,i) in partTwo[partOne.selected].buttons" :key=i>
            <div class="btn-slot">
              <h3>{{item}}</h3>
              <v-spacer></v-spacer>
              <img v-if="partTwo[partOne.selected].selected == i" src="../assets/cir_tick.svg" />
            </div>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <!-- Renewal -->
    <div class="part-3" v-if="partOne.selected==1"> 
      <div><h2>2. Application for</h2></div>
      <div>
        <v-btn-toggle v-model="partTwo[partOne.selected].selected" tile group>
          <v-btn v-for="(item,i) in partTwo[partOne.selected].buttons" :key=i>
            <div class="btn-slot">
              <h3>{{item}}</h3>
              <img v-if="partTwo[partOne.selected].selected == i" src="../assets/cir_tick.svg" />
            </div>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <!-- Application / New Licnce -->
    <div class="part-3" v-if="partOne.selected==0 && !(partTwo[partOne.selected].selected==undefined) && partTwo[partOne.selected].selected!=3 && partThree[partOne.selected][partTwo[partOne.selected].selected].buttons.length>0">
      <div><h2>3. Licence Type</h2></div>
      <div>
        <v-btn-toggle v-model="partThree[partOne.selected][partTwo[partOne.selected].selected].selected" tile group>
          <v-btn v-for="(item,i) in partThree[partOne.selected][partTwo[partOne.selected].selected].buttons" :key=i>
            <div class="btn-slot">
              <h3>{{item}}</h3>
              <img v-if="partThree[partOne.selected][partTwo[partOne.selected].selected].selected == i" src="../assets/cir_tick.svg" />
            </div>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <!-- special style for nomination -->
    <div class="part-2" v-if="partOne.selected==0 && !(partTwo[partOne.selected].selected==undefined) && partTwo[partOne.selected].selected==3 && partThree[partOne.selected][partTwo[partOne.selected].selected].buttons.length>0">
      <div><h2>3. Licence Type</h2></div>
      <div>
        <v-btn-toggle v-model="partThree[partOne.selected][partTwo[partOne.selected].selected].selected" tile group>
          <v-btn v-for="(item,i) in partThree[partOne.selected][partTwo[partOne.selected].selected].buttons" :key=i>
            <div class="btn-slot large">
              <h3>{{item}}</h3>
              <img v-if="partThree[partOne.selected][partTwo[partOne.selected].selected].selected == i" src="../assets/cir_tick.svg" />
            </div>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <!-- Apply -->
    <div class="part-4" v-if="(checkShouldShowApply())">
      <v-btn depressed class="apply-btn rounded-pill" color="primary" @click="tryRouteTo()">Apply</v-btn>
    </div>
     
  </div>
</template>

<script>
import BoldLine from '../components/misc/bold-line'
// @ is an alias to /src


export default {
  name: 'Landing',
  components: {
    BoldLine
  },
  data: () => ({

    partOne: {
      buttons: ['Applications', 'Renewal', 'Follow Up'],
      selected: undefined
    },
    partTwo: [
      { //Parent is Applications
        buttons: ['New Licence', 'Licence Renewal', 'Licence Transfer','Nomination of Reserve Licensee','Temporary Absence','Licence Amendment','Change Information'],
        selected: undefined
      },
      {
        //Parent is Renewal
        buttons: ['Liquor Licence', 'Club Liquor Licence'], 
        selected: undefined
      },
      {
        //Parent is Follow Up
      },
    ],
    partThree: [
      [
        //Parent is Applications
        { //NewLicense
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
        {
          //Licence Renewal
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
        {
          //Licence Transfer
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
        {
          //Nomination of Reserve Licensee
          buttons: ['Nomination of Reserve Licensee', 'Application for Authorisation of a Nominated Reserve Licensee','Declaration of the Nominated Reserved Licensee'], selected: undefined
        },
        {
          //Temporary Absence
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
        {
          //Licence Amendment
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
        {
          //Change Information
          buttons: ['Liquor Licence', 'Club Liquor Licence'], selected: undefined
        },
      ],
      [
        //Parent is Renewal
      ],
      [
        //Parent is Follow Up
      ],
    ]
  }),
  methods: {
    checkShouldShowApply: function (){
      if(!(this.partOne.selected==undefined) && !(this.partTwo[this.partOne.selected].selected==undefined)){
        return true;
      }else if (this.partOne.selected==1){
        return true;
      }
      return false;
    },
    tryRouteTo(){
      var s1 = this.partOne.selected;
      var s2 = this.partTwo[this.partOne.selected].selected;
      var s3 = this.partThree[this.partOne.selected][this.partTwo[this.partOne.selected].selected].selected;
      console.log(s1, s2, s3);
      if(s2==3){
        console.log('route to login');
      }
    }
  },
  mounted(){
    this.$root.$emit('updateNav',0); 
  }
}
</script>

<style scoped lang="scss">

.main-container {
    width:100%;
    height:100%;
    padding:0px 120px 115px;
    h1 {
      font-size: 3.875em;
      margin-top:87px !important;
    }
    #h1-boldline{
      margin:28px 0px 40px 0px;
    }
    h2{
      font-size: 1.625em;
      font-weight: 500;
    }
}

.part-1{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    div:nth-child(2){
      width:100%;
      margin:35px 0px;
      .v-btn-toggle--group{
        display:flex;
        justify-content: center;
        flex-wrap: nowrap;
        width:100%;
      }
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 12px !important;
        padding:20px;
        border-radius:10px !important;
        border:1.5px solid $ols-t-lightGrey;
        width: 300px;
        height:250px;
        background-color:transparent !important;
        font-size:1em;
        .btn-slot{
          width:100%;
        }
        .tick-cir-div{
          height:32px;
          img{
            height:32px;
            width:32px;
          }
        }
        h3{
          text-transform: none !important;
          letter-spacing: normal;
          font-size:1.75em;
          margin-bottom:20px;
        }
      }
      .v-btn-toggle>.v-btn.v-btn--active{
        color:white;
        border:0px;
        background:$ols-primary !important;
      }
    }
}

.part-2{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    div:nth-child(2){
      width:100%;
      margin:35px 0px;
      .v-btn-toggle--group{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width:100%;
      }
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 0px 20px !important;
        border-radius:10px !important;
        border:1.5px solid $ols-t-lightGrey;
        width: 700px;
        min-height:90px;
        background-color:transparent !important;
        font-size:1em;
        .btn-slot{
          width:100%;
          height:100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding:0px 47px 0px 78px;
          &.large{
            text-align: left;
            h3{
              max-width:500px;
              white-space: pre-wrap;
            }
          }
        }
        img{
            height:32px;
            width:32px;
        }
        h3{
          text-transform: none !important;
          letter-spacing: normal;
          font-size:1.5em;
          font-weight:400;
        }
      }
      .v-btn-toggle>.v-btn.v-btn--active{
        color:white;
        border:0px;
        background:$ols-primary !important;
      }
    }
}

.part-3{
   width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    div:nth-child(2){
      width:100%;
      margin:35px 0px;
      display: flex;
      justify-content: center;
      .v-btn-toggle--group{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width:720px;
      }
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 10px 20px !important;
        border-radius:10px !important;
        border:1.5px solid $ols-t-lightGrey;
        width: 340px;
        height:90px;
        background-color:transparent !important;
        font-size:1em;
        .btn-slot{
          width:100%;
          height:100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img{
            position: absolute;
            right:16px;
            height:32px;
            width:32px;
          }
        h3{
          text-transform: none !important;
          letter-spacing: normal;
          font-size:1.5em;
          font-weight:400;
        }
      }
      .v-btn-toggle>.v-btn.v-btn--active{
        color:white;
        border:0px;
        background:$ols-primary !important;
      }
    }
}

.part-4{
  .apply-btn{
    width:180px !important;
    height:50px;
    text-transform: none;
    font-size:1em;
    font-weight: 400 !important;
    span{
      font-size:1em;
      
    }
  }
}

//md and down
@media screen and (max-width: 1263px) {
  .main-container {
    width:100%;
    height:100%;
    padding:0px 14px 57px;
    h1 {
      font-size: 1.625em;
      margin-top:50px !important;
    }
    #h1-boldline{
      margin:20px 0px;
    }
    h2{
      font-size:1em;
      font-weight: 500;
    }
  }

  .part-1{
    padding:0px 15px;
    div:nth-child(2){
      margin:22px 0px 30px;
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 6px !important;
        padding:9px;
        border-radius:5px !important;
        width: 106px;
        height:106px;
        img{
          height:40px;
          margin:0px !important;
        }
        .tick-cir-div{
          height:16px;
          img{
            width:16px;height:16px;
          }
        }
        h3{
          text-transform: none !important;
          letter-spacing: normal;
          font-size:0.9375em;
          font-weight: 500;
          margin-top:5px;
          margin-bottom:8px;
        }
      }
      .v-btn-toggle>.v-btn.v-btn--active{
        color:white;
        border:0px;
        background:$ols-primary !important;
      }
    }
  }

  .part-2{
    div:nth-child(2){
      margin-top:20px;
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 0px 10.25px !important;
        border-radius:5px !important;
        width: 301.5px;
        height:61.5px;
        .btn-slot{
          padding:0px 6px 0px 8px;

        
          &.large{
            text-align: left;
            h3{
              max-width:220px;
              white-space: pre-wrap;
            }
          }

        }
        img{
            height:22px;
            width:22px;
        }
        h3{
          font-size:1em;
          font-weight:500;
        }
      }
    }
  }

  .part-3{
    div:nth-child(2){
      margin-top:20px;
      .v-btn-toggle--group{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width:100%;
      }
      .v-btn-toggle--group>.v-btn.v-btn{
        margin:0px 0px 10.25px !important;
        border-radius:5px !important;
        width: 301.5px;
        height:61.5px;
        .btn-slot{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding:0px 6px 0px 9px;
        }
        img{
            right:5px;
            height:22px;
            width:22px;
        }
        h3{
          font-size:1em;
          font-weight:500;
        }
      }
    }
  }

  .part-4{
    .apply-btn{
    span{
      font-size:1em;
    }
  }
  }

}

</style>
