<template>
  <v-app>
    <div class="ols-app-bar hidden-md-and-down">
      <div class="d-flex align-center">
        <h2>Online License Service</h2>
      </div>
      <v-spacer></v-spacer>
      <ABToggle class="ab-toggle" v-for="(n,i) in nav" :key="i" :title="n.title" :isToggled="n.isToggled" @click.native="navRouteTo(n.route)"></ABToggle>
      <ABButton :dataset="abBtn.fontsize" @itemOnclick="fontsizeChange"></ABButton>
      <ABButton :dataset="abBtn.translate" @itemOnclick="translateTo"></ABButton>
      <v-btn v-if="loggedIn==false" class="login-btn ml-5" rounded color="primary" dark depressed @click="$router.push({name:'Login'})">
        <v-icon v-text="'mdi-account'" class="mr-1" dense></v-icon><span>Login</span>
      </v-btn>
      <v-menu offset-y v-if="loggedIn==true">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text class="simple-btn" color="primary" v-bind="attrs" v-on="on" >
              <v-icon left dense>mdi-account</v-icon>
              <div style="text-decoration:underline">My Account</div>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
        </template>
        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-title v-text="'My Account'" @click="navRouteTo('MAHome')"></v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="logout()"><v-icon class="mr-1">mdi-logout</v-icon>Logout</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
    </v-menu>



      
      <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </div>
    <div class="ols-app-bar-sm hidden-lg-and-up">
      <div class="d-flex align-center" style="font-size:12px">
        <h2>Online License Service</h2>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon color="primary" @click="menu=!menu"  >
        <v-icon v-text="menu?'mdi-close':'mdi-menu'" class="fs-28"></v-icon>  <!-- inline style 'font-size' is used to control the size of a icon-->
      </v-btn>
    </div>
    <v-main>
      <!-- <router-link to="/landing">Landing</router-link> -->
      <div class="app-menu-div" v-show="menu">
        <div>
          <ABButton :dataset="abBtn.fontsize" @itemOnclick="fontsizeChange"></ABButton>
          <ABButton :dataset="abBtn.translate" @itemOnclick="translateTo"></ABButton>
        </div>
        <div v-ripple class="clickable-div" @click="smallRoute('Landing')">
          <h2>Services</h2>
        </div>
        <div v-ripple class="clickable-div" @click="smallRoute('InOverview')">
          <h2>Application Instructions</h2>
        </div>
        <div v-ripple class="clickable-div" @click="smallRoute('FAQDca')"> 
          <h2>FAQ</h2>
        </div>
        <div>
          <v-btn class="login-btn ml-5" rounded color="primary" dark depressed @click="$router.push({name:'Login'})">
            <v-icon v-text="'mdi-account'" class="mr-1" dense></v-icon><span>Login</span>
          </v-btn>
        </div>
      </div>
      <router-view v-show="!menu"/>
    </v-main>
    <div class="ols-app-footer">
      <div class="footer-content">
        <h5>Licensing Offices</h5>
        <h4>Hong Kong Island and Outlying Islands</h4>
        <h4>Kowloon</h4>
        <h4>New Territories</h4>
        <div style="display:flex" class="hidden-md-and-down">
          <h6>Disclaimer</h6>
          <h6>Privacy Statement</h6>
          <v-spacer></v-spacer>
          <h6>©Food and Environmental Hygiene Department</h6>
        </div>
          <div class="hidden-lg-and-up">
            <div style="display:flex" >
            <h6>Disclaimer</h6>
            <h6>Privacy Statement</h6>
          </div>
          <h6 id="copyright-h6" >©Food and Environmental Hygiene Department</h6>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ABToggle from './components/app-bar/ABToggle';
import ABButton from './components/app-bar/ABButton';

export default {
  name: 'App',

  components: {
    ABToggle, ABButton
  },
  data: () => ({
    menu:false,
    loggedIn:false,
    nav: [
      {title:'Services',isToggled:true,route:"Landing"},
      {title:'Application Instructions',isToggled:false,route:"InOverview"},
      {title:'FAQ',isToggled:false,route:"FAQDca"},
    ],
    abBtn:{
      fontsize:{
        text:"AA",
        subheader:"Font size",
        isFontsize:true,
        selectedItem:1,
        items:[
          {text:'Small'},
          {text:'Medium'},
          {text:'Large'},
        ],
      },
      translate:{
        text:"EN",
        subheader:"Language",
        isFontsize:false,
        selectedItem:0,
        items:[
          {text:'EN'},
          {text:'TC'},
        ],
      }
    },
    fontsize:{
      step: 0.125,
      default: 1, // The default font size of the entire html element which is delcared in main.scss
    }
  }),
  created(){
    this.$root.$on('updateNav',this.updateNavEventHandler)
    this.$root.$on('login',this.logUserIn)
  },
  methods: {
    logout(){
      this.loggedIn = false;
      this.$router.push({name: 'Landing'})
    },
    logUserIn(){
      this.loggedIn = true;
      this.$router.push({name: 'MAHome'})
    },
    navRouteTo: function (target){
      this.$router.push({name: target})
    },
    smallRoute: function (target){
      this.$router.push({name: target});
      this.menu = !this.menu;
    },
    fontsizeChange: function (inValue){
      var minFontsize = this.fontsize.default - this.fontsize.step;
      document.getElementsByTagName('main')[0].style.fontSize = (minFontsize + inValue*this.fontsize.step) + 'rem';
    },
    translateTo: function (inValue){
      alert('translateTo: ' + this.abBtn.translate.items[inValue].text);
    },
    updateNavEventHandler: function (inValue){
      this.nav.forEach(element => {
        element.isToggled = false;
      });
      if(inValue>=0 && inValue<=2){
        this.nav[inValue].isToggled = true;
      }
    }
  },
  
};
</script>

<style lang="scss">
@import '@/scss/main.scss';
.ols-app-bar{
  .ab-toggle{
    margin-right:33px !important;
    &:nth-child(5){
      margin-right:29px !important;
    }
  }
}
.login-btn{
  font-size:1rem !important;
  font-weight:400;
  text-transform: none !important;
  min-height:45px;
  min-width:135px !important;
  span{
    margin-right:6px;
  }
}

//md and down
@media screen and (min-width: 1263px) {
.app-menu-div{
  display: none;
}
}


</style>
